import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Post from "../views/layouts/Post"

const NewsPostTemplate = props => {
  const contents = JSON.parse(
    get(props, "data.newsPostJson.content.content", "")
  )
  const title = get(props, "data.newsPostJson.title")
  const createdAt = get(props, "data.newsPostJson.createdAt")
  const url = get(props, "data.newsPostJson.thumbnail.fluid", "")
  const options = {
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <Post>
      <h3 className="mb-3">{title}</h3>
      <p className="time">
        <i className="icon-time"></i> {createdAt}
      </p>
      <Img className="img-responsive" fluid={url} />
      <div className="hr_20" />
      <div>{documentToReactComponents(contents, options)}</div>
    </Post>
  )
}

export default NewsPostTemplate

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    newsPostJson: contentfulNewsPost(slug: { eq: $slug }) {
      title
      slug
      createdAt(formatString: "MMMM Do, YYYY")
      thumbnail {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        content
      }
    }
  }
`
